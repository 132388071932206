import * as React from "react";
import { IonIcon } from "@ionic/react";
import camelCase from "lodash/camelCase";

import ionicons from "./ionicons";

const EnhancedIonIcon: React.FC<INodeIonIcon["properties"]> = ({
  color,
  icon,
  ...rest
}) => {
  return <IonIcon color={color} icon={ionicons[camelCase(icon)]} {...rest} />;
};

export default EnhancedIonIcon;

import * as React from "react";

import { createMemoryHistory, MemoryHistory } from "history";
import { IonReactMemoryRouter } from "@ionic/react-router";
import { IonApp, IonRouterOutlet, RouterDirection } from "@ionic/react";
import { Route } from "react-router";

import { Page, Blanket } from "./";
import "@ionic/react/css/ionic.bundle.css";

type IonRouteAction = "push" | "replace" | "pop";

interface LocationState {
  direction?: RouterDirection;
  action?: IonRouteAction;
}

const history = createMemoryHistory() as MemoryHistory<LocationState>;

const Preview: React.FC<{ nodes: INode[]; dispatch: IDispatch }> = ({
  nodes,
  dispatch
}) => (
  <Blanket
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.2)",
      zIndex: 9999
    }}
    onClick={() => {
      dispatch({
        type: "toggle-preview"
      });
    }}
  >
    <div
      style={{
        width: 375,
        height: 812,
        border: 0,
        position: "relative"
        // width: window.innerHeight * 0.9 * 0.4618226601,
        // height: window.innerHeight * 0.9,
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <IonApp>
        <IonReactMemoryRouter history={history}>
          <IonRouterOutlet>
            <React.Fragment>
              {nodes.map(
                (
                  { id, children, properties: { elementName } },
                  pageIndex: number
                ) => (
                  <Route
                    key={id}
                    path={pageIndex === 0 ? "/" : `/${elementName}`}
                    render={props => (
                      <Page
                        routeProps={props}
                        preview={true}
                        items={children}
                        pagePath={{ id, elementName, index: pageIndex }}
                      />
                    )}
                  />
                )
              )}
            </React.Fragment>
          </IonRouterOutlet>
        </IonReactMemoryRouter>
      </IonApp>
    </div>
  </Blanket>
);

export default Preview;

import * as React from "react";
import styled from "styled-components";
import { Rnd } from "react-rnd";

const ArtboardTitle = styled.span`
  transform-origin: 0 100%;
  transform: scale(calc(1 / var(--scale)));
`;

const Handle = styled.div.attrs(() => ({ className: "handle" }))`
  user-select: none;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: #959595;
  font-size: 12px;

  &:hover {
    color: #18a0fb;
  }

  &:active {
    color: #18a0fb;
  }

  top: -24px;
  left: -8px;
  bottom: -8px;
  right: -8px;

  padding: 0 8px;
`;

const Box = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .handle:hover + &,
  .handle:active + & {
    outline: 2px solid #4d80e4;
  }
`;

interface ArtboardProps {
  children: React.ReactElement;
  name: string;
  dispatch: any;
  transform: ITransform;
  id: string;
  scale: number;
  index: number;
}

const Content: React.FC = ({ children }) => <Box>{children}</Box>;

const MemoContent: any = React.memo(Content);

const Artboard = ({
  children,
  name,
  dispatch,
  transform,
  id,
  scale,
  index
}: ArtboardProps) => {
  const pathItem: IPathItem = {
    id,
    elementName: name,
    index
  };

  const openArtboard = React.useCallback(() => {
    dispatch({
      type: "open-artboard",
      pathItem
    });
  }, [dispatch, pathItem]);

  const deselect = React.useCallback(() => {
    dispatch({
      type: "deselect-all"
    });
  }, [dispatch]);

  const moveArtboard = React.useCallback(
    (e, d) => {
      dispatch({
        type: "move-artboard",
        node: { id: id, x: d.x, y: d.y }
      });
    },
    [dispatch, id]
  );

  const ref = React.useRef<Rnd>();

  React.useEffect(() => {
    if (ref.current) {
      ref?.current?.updatePosition({ x: transform.x, y: transform.y });
    }
  }, [transform]);

  return (
    <Rnd
      onClick={openArtboard}
      onDragStart={deselect}
      scale={scale}
      onDragStop={moveArtboard}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
      size={{
        width: 375,
        height: 812
      }}
      style={{ cursor: "normal" }}
      dragHandleClassName="handle"
      maxWidth={375}
      maxHeight={812}
      ref={ref as any}
    >
      <Handle>
        <ArtboardTitle>{name}</ArtboardTitle>
      </Handle>
      <MemoContent>{children}</MemoContent>
    </Rnd>
  );
};

export default React.memo(Artboard);

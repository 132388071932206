import styled from "styled-components";

const Back = styled.button<{ side: "left" | "right" }>`
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  top: 8px;
  position: absolute;
  padding: 0;

  outline: none;
  ${({ side }) => side}: 8px;
`;

export default Back;

import * as React from "react";
import SplitPane from "react-split-pane";
import styled from "styled-components";
import { useKey } from "react-use";
import throttle from "lodash/throttle";
import is from "is_js";

import { IonApp } from "@ionic/react";

import "./app.css";

import {
  NodeList,
  Canvas,
  Artboard,
  Page,
  Inspector,
  Toolbar,
  Preview
} from "./components";

import { Context, Reducer } from "./models";

import data from "./models/data";

import { useGesture } from "react-use-gesture";
import clamp from "lodash/clamp";

import useKeyPress from "./hooks/use-key-press";
const local = localStorage.getItem("data");

const initialData = local !== null ? JSON.parse(local) : data;

const MAX_SCALE = 5;
const MIN_SCALE = 0.25;

type Tuple = [number, number];

function updateCSSScale(scale: string) {
  document.body.style.setProperty("--scale", scale);
}
const dbUpdateCSSScale = throttle(updateCSSScale, 50);

interface CanvasTransform {
  canvasX: number;
  canvasY: number;
  canvasScale: number;
}

function updateCanvasTransform(
  domNode: HTMLElement | undefined,
  { canvasX, canvasY, canvasScale }: CanvasTransform
) {
  if (typeof domNode !== "undefined") {
    domNode.style.transform = `translate(${canvasX}px, ${canvasY}px) scale(${canvasScale})`;
  }
}

const Pane = styled.div<{ backgroundColor?: string; isDragging?: boolean }>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};
  min-height: 100%;
  cursor: ${({ isDragging }) =>
    isDragging
      ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAbCAYAAACJISRoAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALbSURBVHgBpVbNjhJBGGwGZoBBdNmFsCDrQoIaMRtJMJ55gg0XzsaT657wbgIHH4A3gIv6CN68+BoGUBM9kICI/BgIY9VMzwrIwjB+SaV7oKfrq+qvu0cI9+GR2BmKcBYeOdZbKBRUtH6Px6Plcjn2vfI/R4T/hGEYolwucxJfOp0ORKPRMPovgB9AB7gEbgJBQHNFVqvV+JIfeC4n/goM2u220Wq1DPYlOiSHuqB8x3EwI/UAgfYLJyYsgYaxWCwMTGosETKJQ1ioiT3UcCBtOLYnZqyT2H38vggEAqeRSOQW+prP51Os4VtCyg4BdxySED+Bz8AF/vPvtE4OuAGknZCsWdcHqEgVO6wzSWBBxinJsnVAghVJkq1yYrGYkDa4CbXT6XjtbDcG7BLdbldMp1PhMkiw81RQoIRr4taujNy8yooSjDGZ7ZZKsCbCbZDYzHaZAMFnDe09Ye10DXatJILF3Nh3GvSQ++INNjkzYL2/BB6KJbs2BS3KZDIb7braK+xks1lmfo7szMGsefbx27ddJOuENomw9tnfNRmNRlTyaTAYDFF6phUgEtVqNZHP58V/B9cjkUjo6CaBV1TQbDYNN7F0Op8mk0nOeSWE1cTT88jv93PRL4DvlUrF6Pf7e5E0Gg2SfABSKCB9/fyiXWSOSaJz4P0+qnq9HteR6/EsFArF7WNlxTbegtK2I+yPNNpHwFOqKhaLZjFsIyiVSlTxFrgr7yFVXBPmXuG9oOt6Aqruq6r6WFGU1zYZldmEtLNer9uV+A44g4pj6YqytRAwmU9Yl9ZhMBg80TTtAfpPAJJ9BIbCOjyH8vkSY84w9jb6YSTlM6yTY+c1yUxIpobD4cB8Ptcnk4kOZfaHg2c2m5HoNzCG8l/j8ZikPFXnwmnIbEwL4/E4T4SIsK7kFHAiWz4f8EOCDtgK3IRJxu8uXq2pVIpqdE7MKoI9Kst1E4Frxmve3XjB/QFjF5Ua9tnKKQAAAABJRU5ErkJggg=='), auto"
      : "default"};
`;

function findMinMax(nodes: INode[]) {
  let minX = nodes[0].properties.transform.x,
    maxX = nodes[0].properties.transform.x;
  let minY = nodes[0].properties.transform.y,
    maxY = nodes[0].properties.transform.y;

  for (let i = 1, len = nodes.length; i < len; i++) {
    let x = nodes[i].properties.transform.x;
    minX = x < minX ? x : minX;
    maxX = x > maxX ? x : maxX;

    let y = nodes[i].properties.transform.y;
    minY = y < minY ? y : minY;
    maxY = y > maxY ? y : maxY;
  }

  return {
    minX,
    maxX,
    minY,
    maxY
  };
}

const getCanvasDimensions = (artboards: INode[]) => {
  if (artboards.length > 0) {
    const minMax = findMinMax(artboards);

    const artboardWidth = 375;
    const artboardHeight = 812;

    const canvas = {
      width: minMax.maxX + artboardWidth + 200,
      height: minMax.maxY + artboardHeight + 200
    };

    return canvas;
  } else {
    return {
      width: 0,
      height: 0
    };
  }
};

const deselectors = [
  "node-list-pane",
  "canvas-pane",
  "inspector-pane",
  "node-list",
  "canvas",
  "inspector"
];

const deleteStoppers = ["INPUT", "TEXTAREA"];

const App: React.FC = () => {
  const [store, dispatch] = React.useReducer(Reducer, initialData);

  const { nodes } = store;

  const { width, height } = getCanvasDimensions(nodes);

  const paneRef = React.useRef<HTMLDivElement>();
  const canvasRef = React.useRef<HTMLDivElement>();

  const deselect = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const tagName = (event.target as HTMLElement).tagName;
    const id = (event.target as HTMLElement).id;

    if (deselectors.includes(id) || tagName === "ION-CONTENT") {
      dispatch({ type: "deselect-all" });
    }
  }, []);

  const [wheeling, setWheeling] = React.useState(false);

  const transformRef = React.useRef({
    canvasX: 0,
    canvasY: 0,
    canvasScale: 1
  });

  const spacePress = useKeyPress(" ");

  useKey("Delete", e => {
    const tagName = (e.target as HTMLElement).tagName;
    if (!deleteStoppers.includes(tagName)) {
      dispatch({
        type: "delete-node"
      });
    }
  });

  useKey("Backspace", e => {
    const tagName = (e.target as HTMLElement).tagName;
    if (!deleteStoppers.includes(tagName)) {
      dispatch({
        type: "delete-node"
      });
    }
  });

  const bind = useGesture({
    onDrag: ({ delta: [deltaX, deltaY] }) => {
      if (spacePress) {
        const { canvasX, canvasY } = transformRef.current;

        const offsetX = canvasX + deltaX;
        const offsetY = canvasY + deltaY;

        const newTransform = {
          ...transformRef.current,
          canvasX: offsetX,
          canvasY: offsetY
        };

        updateCanvasTransform(canvasRef.current, newTransform);

        transformRef.current = newTransform;
      }
    },
    onWheel: ({
      wheeling,
      ctrlKey,
      metaKey,
      delta: [deltaX, deltaY],
      event
    }) => {
      setWheeling(wheeling);

      const isZooming = (is.mac() && metaKey) || (!is.mac() && ctrlKey);

      const { canvasX, canvasY, canvasScale } = transformRef.current;
      if (isZooming && event) {
        const factor = deltaY * 0.001;

        const { clientX, clientY } = event as React.WheelEvent;

        const newScale = clamp(canvasScale + factor, MIN_SCALE, MAX_SCALE);
        // const newScale = 1;

        const scaleRatio = newScale / canvasScale;

        if (newScale < MAX_SCALE && newScale > MIN_SCALE) {
          const pane = paneRef.current;
          const canvas = canvasRef.current;

          if (typeof canvas !== "undefined" && typeof pane !== "undefined") {
            const rect = canvas.getBoundingClientRect();
            const currentCenterX = rect.x + rect.width / 2;
            const currentCenterY = rect.y + rect.height / 2;

            const paneRect = pane.getBoundingClientRect();
            const originCenterX = paneRect.x + paneRect.width / 2;
            const originCenterY = paneRect.y + paneRect.height / 2;

            const mousePosToCurrentCenterDistanceX = clientX - currentCenterX;
            const mousePosToCurrentCenterDistanceY = clientY - currentCenterY;

            const newCenterX =
              currentCenterX +
              mousePosToCurrentCenterDistanceX * (1 - scaleRatio);
            const newCenterY =
              currentCenterY +
              mousePosToCurrentCenterDistanceY * (1 - scaleRatio);

            const offsetX = newCenterX - originCenterX;
            const offsetY = newCenterY - originCenterY;

            const newTransform = {
              ...transformRef.current,
              canvasX: offsetX,
              canvasY: offsetY,
              canvasScale: newScale
            };

            updateCanvasTransform(canvasRef.current, newTransform);

            transformRef.current = newTransform;

            dbUpdateCSSScale(newScale.toString());
          }
        }
      } else if (!isZooming) {
        const newTransform = {
          ...transformRef.current,
          canvasX: canvasX - deltaX,
          canvasY: canvasY - deltaY
        };

        updateCanvasTransform(canvasRef.current, newTransform);

        transformRef.current = newTransform;
      }
    }
  });

  return (
    <div onClick={deselect} className={wheeling ? "is-wheeling" : ""}>
      <Context.Provider value={{ store, dispatch, wheeling }}>
        {store.showPreview && <Preview nodes={nodes} dispatch={dispatch} />}
        <Toolbar />
        <SplitPane split="vertical" defaultSize="220px">
          <Pane id="node-list-pane" backgroundColor="white">
            <NodeList id="node-list" />
          </Pane>
          <SplitPane split="vertical" defaultSize="220px" primary="second">
            <Pane
              id="canvas-pane"
              {...bind()}
              isDragging={spacePress}
              className={spacePress ? "space-press" : ""}
              ref={paneRef as any}
            >
              <Canvas id="canvas" ref={canvasRef as any}>
                {nodes.map(
                  (
                    { id, children, properties: { elementName, transform } },
                    pageIndex: number
                  ) => (
                    <Artboard
                      key={id}
                      id={id}
                      name={elementName}
                      transform={transform}
                      dispatch={dispatch}
                      scale={transformRef.current.canvasScale}
                      index={pageIndex}
                    >
                      <IonApp>
                        <Page
                          items={children}
                          pagePath={{ id, elementName, index: pageIndex }}
                        />
                      </IonApp>
                    </Artboard>
                  )
                )}
              </Canvas>
            </Pane>
            <Pane id="inspector-pane" backgroundColor="white">
              <Inspector />
            </Pane>
          </SplitPane>
        </SplitPane>
      </Context.Provider>
    </div>
  );
};

export default App;

import * as React from "react";
import { NodeType } from "../../models";

import {
  Segments,
  ColorSelect,
  IconSelect,
  TextField,
  TextArea,
  Panel,
  Toggle,
  Title,
  Select
} from "./panels";

enum PanelType {
  TextField = "TextField",
  TextArea = "TextArea",
  NumberField = "NumberField",
  IonicColor = "IonicColor",
  Icon = "Icon",
  Segments = "Segments",
  Select = "Select",
  Toggle = "Toggle",
  Action = "Action",
  Page = "Page"
}

const iconPositions = [
  { value: "start", label: "Start" },
  { value: "end", label: "End" }
];

const sizes = [
  { value: "default", label: "Normal" },
  { value: "large", label: "Large" },
  { value: "small", label: "Small" }
];

const tags = [
  { value: "p", label: "Body" },
  { value: "h1", label: "Heading 1" },
  { value: "h2", label: "Heading 2" },
  { value: "h3", label: "Heading 3" },
  { value: "h4", label: "Heading 4" },
  { value: "h5", label: "Heading 5" },
  { value: "h6", label: "Heading 6" }
];

const actions = [
  { value: "", label: "None" },
  { value: "goto", label: "Link to Page" }
];

const direction = [
  { value: "vertical", label: "Vertical" },
  { value: "horizontal", label: "Horizontal" }
];

const alignItems = [
  { value: "ion-align-items-start", label: "Start" },
  { value: "ion-align-items-center", label: "Center" },
  { value: "ion-align-items-end", label: "End" }
  // { value: "ion-align-items-stretch", label: "Stretch" }
];

const justifyContent = [
  { value: "ion-justify-content-start", label: "Start" },
  { value: "ion-justify-content-center", label: "Center" },
  { value: "ion-justify-content-end", label: "End" },
  { value: "ion-justify-content-around", label: "Space Around" },
  { value: "ion-justify-content-evenly", label: "Space Evenly" },
  { value: "ion-justify-content-between", label: "Space Between" }
];

const expand = [
  { value: "block", label: "Normal" },
  { value: "full", label: "Full" }
];

const shape = [
  { value: "", label: "Normal" },
  { value: "round", label: "Round" }
];

const fill = [
  { value: "solid", label: "Filled" },
  { value: "outline", label: "Outline" },
  { value: "clear", label: "Clear" }
];

const inputModes = [
  { value: "decimal", label: "Decimal" },
  { value: "email", label: "Email" },
  { value: "none", label: "None" },
  { value: "numeric", label: "Numeric" },
  { value: "search", label: "Search" },
  { value: "tel", label: "Tel" },
  { value: "text", label: "Text" },
  { value: "url", label: "Url" }
];

const inputTypes = [
  { value: "date", label: "Date" },
  { value: "email", label: "Email" },
  { value: "number", label: "Number" },
  { value: "password", label: "Password" },
  { value: "search", label: "Search" },
  { value: "tel", label: "Tel" },
  { value: "text", label: "Text" },
  { value: "time", label: "Time" },
  { value: "url", label: "Url" }
];

interface InspectorPanel {
  type: PanelType;
  property: string;
  [key: string]: any;
}

interface InspectorMapping {
  [key: string]: InspectorPanel[];
}

const mapping: InspectorMapping = {
  [NodeType.IonButton]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      property: "label",
      toggle: {
        property: "slot",
        to: "icon-only",
        defaultValue: "start"
      }
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    },
    {
      type: PanelType.Segments,
      property: "fill",
      defaultValue: "",
      options: fill
    },
    {
      type: PanelType.Segments,
      property: "size",
      defaultValue: "",
      options: sizes
    },
    {
      type: PanelType.Segments,
      property: "expand",
      defaultValue: "",
      options: expand
    },
    {
      type: PanelType.Segments,
      property: "shape",
      defaultValue: "",
      options: shape
    },
    {
      type: PanelType.Icon,
      property: "icon",
      defaultValue: ""
    },
    {
      type: PanelType.Segments,
      property: "slot",
      niceName: "Icon Position",
      options: iconPositions,
      defaultValue: "start"
    },
    {
      type: PanelType.Action,
      property: "action",
      options: actions
    },
    {
      type: PanelType.Page,
      property: "goto",
      niceName: "Page"
    }
  ],
  [NodeType.IonStack]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.Segments,
      property: "direction",
      options: direction,
      defaultValue: "vertical"
    },
    {
      type: PanelType.Select,
      property: "distribute",
      options: justifyContent
    },
    {
      type: PanelType.Segments,
      property: "align",
      options: alignItems
    },
    {
      niceName: "Item Spacing",
      type: PanelType.NumberField,
      property: "itemSpacing"
    },
    {
      type: PanelType.NumberField,
      property: "padding",
      min: 0
    }
  ],
  [NodeType.IonText]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    },
    {
      type: PanelType.TextField,
      property: "content"
    },
    {
      type: PanelType.Select,
      niceName: "Type",
      property: "size",
      options: tags,
      defaultValue: "p"
    }
  ],
  [NodeType.IonLabel]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      niceName: "Content",
      property: "children"
    }
  ],
  [NodeType.IonCardTitle]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      niceName: "Content",
      property: "children"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    }
  ],
  [NodeType.IonCardSubtitle]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      niceName: "Content",
      property: "children"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    }
  ],
  [NodeType.IonCardContent]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      niceName: "Content",
      property: "children"
    }
  ],
  [NodeType.IonCardHeader]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    }
  ],
  [NodeType.IonBadge]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      niceName: "Content",
      property: "children"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    }
  ],
  [NodeType.IonCheckbox]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      property: "value"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    }
  ],
  [NodeType.IonRadio]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      property: "value"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    }
  ],
  [NodeType.IonRadioGroup]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      property: "value"
    },
    {
      type: PanelType.Toggle,
      niceName: "Allow Deselect",
      property: "allowEmptySelection",
      compact: true
    }
  ],
  [NodeType.IonImg]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      property: "src"
    },
    {
      type: PanelType.TextField,
      property: "alt"
    }
  ],
  [NodeType.IonChip]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    },
    {
      type: PanelType.Toggle,
      property: "outline",
      on: true,
      off: false,
      compact: true
    }
  ],
  [NodeType.IonInput]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.TextField,
      property: "value"
    },
    {
      type: PanelType.Toggle,
      property: "autocomplete",
      on: "on",
      off: "off",
      compact: true
    },
    {
      type: PanelType.Toggle,
      property: "autocorrect",
      on: "on",
      off: "off",
      compact: true
    },
    {
      type: PanelType.Toggle,
      property: "autofocus",
      on: true,
      off: false,
      compact: true
    },
    {
      type: PanelType.Toggle,
      niceName: "Clear Button",
      property: "clearInput",
      on: true,
      off: false,
      compact: true
    },
    {
      type: PanelType.Toggle,
      niceName: "Clear On Edit",
      property: "clearOnEdit",
      on: true,
      off: false,
      compact: true
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    },
    {
      type: PanelType.NumberField,
      property: "debounce",
      defaultValue: 0
    },
    {
      type: PanelType.Toggle,
      property: "disabled",
      on: true,
      off: false,
      compact: true
    },
    {
      type: PanelType.Select,
      niceName: "Mode",
      property: "inputmode",
      options: inputModes
    },
    {
      type: PanelType.NumberField,
      property: "max",
      defaultValue: 0
    },
    {
      type: PanelType.NumberField,
      property: "min",
      defaultValue: 0
    },
    {
      type: PanelType.NumberField,
      property: "maxlength",
      defaultValue: 0
    },
    {
      type: PanelType.NumberField,
      property: "minlength",
      defaultValue: 0
    },
    {
      type: PanelType.Toggle,
      property: "multiple",
      on: true,
      off: false,
      compact: true
    },
    {
      type: PanelType.TextField,
      property: "pattern"
    },
    {
      type: PanelType.TextField,
      property: "placeholder"
    },
    {
      type: PanelType.Toggle,
      property: "readonly",
      on: true,
      off: false,
      compact: true
    },
    {
      type: PanelType.NumberField,
      property: "size",
      defaultValue: 0
    },
    {
      type: PanelType.NumberField,
      property: "spellcheck",
      defaultValue: 0
    },
    {
      type: PanelType.Select,
      property: "type",
      options: inputTypes
    }
  ],
  [NodeType.IonIcon]: [
    {
      type: PanelType.TextField,
      niceName: "Name",
      property: "elementName"
    },
    {
      type: PanelType.IonicColor,
      property: "color"
    },
    {
      type: PanelType.Icon,
      property: "icon"
    }
  ]
};

interface IPanel {
  panel: InspectorPanel;
  node: INode;
  dispatch: IDispatch;
  index: number;
  screens: string[];
}

const renderPanel = ({ panel, node, dispatch, index, screens }: IPanel) => {
  const onChange = (
    changed:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.MouseEvent<HTMLButtonElement>
      | string,
    property?: string
  ) => {
    let value;
    if (typeof changed === "string") {
      value = changed;
    } else if (changed.currentTarget.type === "checkbox") {
      value =
        node.properties[panel.property] === panel.on ? panel.off : panel.on;
    } else {
      value = changed.currentTarget.value;
    }

    dispatch({
      type: "update-properties",
      node: {
        id: node.id,
        properties: {
          [property || panel.property]: value
        }
      }
    });
  };

  const onIconChange = (icon: any) => {
    dispatch({
      type: "update-properties",
      node: {
        id: node.id,
        properties: {
          [panel.property]: icon.name
        }
      }
    });
  };

  const onToggle = (property: string, to: string, defaultValue: string) => {
    const targetToggle = node.properties[property] === to ? defaultValue : to;
    dispatch({
      type: "update-properties",
      node: {
        id: node.id,
        properties: {
          [property]: targetToggle
        }
      }
    });
  };

  const toggle = panel.toggle;
  const ToggleConditional = () => {
    if (toggle) {
      return (
        <Toggle
          small
          onChange={() => {
            onToggle(toggle.property, toggle.to, toggle.defaultValue);
          }}
          checked={node.properties[toggle.property] !== toggle.to}
        />
      );
    } else {
      return null;
    }
  };

  const panelName = panel.niceName || panel.property;

  switch (panel.type) {
    case PanelType.TextField:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>
            {panelName} <ToggleConditional />
          </Title>
          <TextField
            defaultValue={
              panel.property === "children"
                ? node.children
                  ? node.children[0]
                  : false
                : node.properties[panel.property]
            }
            onChange={onChange}
          />
        </Panel>
      );
    case PanelType.TextArea:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>
            {panelName} <ToggleConditional />
          </Title>
          <TextArea
            defaultValue={
              panel.property === "children"
                ? node.children
                  ? node.children[0]
                  : false
                : node.properties[panel.property]
            }
            onChange={onChange}
            rows={5}
          />
        </Panel>
      );
    case PanelType.NumberField:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>
            {panelName} <ToggleConditional />
          </Title>
          <TextField
            defaultValue={
              panel.property === "children"
                ? node.children
                  ? node.children[0]
                  : false
                : node.properties[panel.property] || 0
            }
            onChange={onChange}
            type="number"
            min={panel.min}
            max={panel.max}
            step={panel.step}
          />
        </Panel>
      );
    case PanelType.Segments:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>
            {panelName} <ToggleConditional />
          </Title>
          <Segments
            options={panel.options}
            selected={node.properties[panel.property] || panel.defaultValue}
            onOptionClick={onChange}
          />
        </Panel>
      );
    case PanelType.Select:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>
            {panelName} <ToggleConditional />
          </Title>
          <Select
            onChange={onChange}
            defaultValue={node.properties[panel.property] || panel.defaultValue}
          >
            {panel.options.map((opt: any, index: number) => (
              <option value={opt.value} key={index}>
                {opt.label}
              </option>
            ))}
          </Select>
        </Panel>
      );
    case PanelType.IonicColor:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>
            {panelName} <ToggleConditional />
          </Title>
          <ColorSelect
            selected={node.properties[panel.property] || panel.defaultValue}
            onChange={onChange}
          />
        </Panel>
      );

    case PanelType.Icon:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>
            {panelName} <ToggleConditional />
          </Title>
          <IconSelect
            onIconChange={onIconChange}
            selectedIcon={node.properties[panel.property] || panel.defaultValue}
          />
        </Panel>
      );
    case PanelType.Toggle:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>{panelName}</Title>
          <Toggle
            small
            onChange={onChange}
            checked={node.properties[panel.property] === panel.on}
          />
        </Panel>
      );
    case PanelType.Action:
      return (
        <Panel key={index} compact={panel.compact}>
          <Title>{panelName}</Title>
          <Select
            onChange={onChange}
            defaultValue={node.properties[panel.property] || panel.defaultValue}
          >
            {panel.options.map((opt: any, index: number) => (
              <option value={opt.value} key={index}>
                {opt.label}
              </option>
            ))}
          </Select>
          {node.properties["action"] === "goto" && (
            <Select
              onChange={e => onChange(e, "goto")}
              defaultValue={node.properties["goto"] || "DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select Page...
              </option>
              {screens.map((opt: any, index: number) => (
                <option value={opt} key={index}>
                  {opt}
                </option>
              ))}
            </Select>
          )}
        </Panel>
      );
    default:
  }
};

const renderInspector = (
  node: INode,
  dispatch: IDispatch,
  screens: string[]
) => {
  const inspector = mapping[node.type];

  if (inspector) {
    const inspectorPanels = inspector.map((panel, index) =>
      renderPanel({ panel, node, dispatch, index, screens })
    );
    return inspectorPanels;
  }
  return <div />;
};

export default renderInspector;

import * as React from "react";
import styled from "styled-components";
import PopoverSelect from "./popover-select";

const Color = styled.button<{ isSelected: boolean; color: string }>`
  user-select: none;
  border: none;
  outline: none;
  margin: 0;
  box-shadow: ${props =>
    props.isSelected ? "inset 0 0 0 2px #18a0fb" : "none"};

  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${props => `var(--ion-color-${props.color})`};
    margin-right: 8px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    background-image: ${props =>
      props.color
        ? "none"
        : `url("data:image/svg+xml,%3Csvg width='4' height='1' viewBox='0 0 4 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='4' height='1' fill='%23C4C4C4'/%3E%3C/svg%3E%0A")`};
    background-repeat: no-repeat;
    background-position: center;
  }

  box-sizing: border-box;
  padding: 8px;

  background: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  color: #333;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  align-items: stretch;
  padding: 8px;
  grid-gap: 8px;
`;

const ColorPreview = styled.div<{ color: string }>`
  height: 32px;
  padding: 0 8px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 0 8px;

  &:hover {
    border-color: #999;
  }

  &:focus {
    border: 1px solid #3273dc;
    outline: none;
    box-shadow: 0 0 0 1px rgba(50, 115, 220, 0.25);
  }

  color: #333;
  user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${props => `var(--ion-color-${props.color})`};
    margin-right: 8px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    background-image: ${props =>
      props.color
        ? "none"
        : `url("data:image/svg+xml,%3Csvg width='4' height='1' viewBox='0 0 4 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='4' height='1' fill='%23C4C4C4'/%3E%3C/svg%3E%0A")`};
    background-repeat: no-repeat;
    background-position: center;
  }

  text-transform: capitalize;
`;

const colors: string[] = [
  "primary",
  "secondary",
  "tertiary",
  "success",
  "warning",
  "danger",
  "light",
  "medium",
  "dark",
  ""
];

const ColorSelect: React.FC<{
  selected: string;
  onChange: (color: string) => void;
}> = ({ selected, onChange }) => {
  const Trigger = (
    <ColorPreview color={selected}>{selected || "Default"}</ColorPreview>
  );

  return (
    <PopoverSelect trigger={Trigger} height={408}>
      <Wrapper>
        {colors.map((color, index) => (
          <Color
            key={index}
            isSelected={
              color === selected ||
              (typeof selected === "undefined" && color.length === 0)
            }
            color={color}
            onClick={() => onChange(color)}
          >
            {color || "Default"}
          </Color>
        ))}
      </Wrapper>
    </PopoverSelect>
  );
};

export default ColorSelect;

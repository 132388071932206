import * as React from "react";
import { IonIcon } from "@ionic/react";
import styled from "styled-components";
import ionicons from "../../../enhanced-ionic/ionicons";

const Button = styled.button<{ isSelected: boolean }>`
  color: #333;
  background: transparent;
  border: 0;
  border-radius: 4px;
  appearance: none;
  font-size: 24px;
  outline: 0;
  animation: fadeIn 200ms ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  box-shadow: ${({ isSelected }) =>
    isSelected ? "inset 0 0 0 2px #18a0fb" : "none"};

  &:focus,
  &:active {
    box-shadow: inset 0 0 0 2px #18a0fb;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IconButtonProps {
  name: string;
  style: object;
  isSelected: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function IconButton({ name, style, onClick, isSelected }: IconButtonProps) {
  return (
    <Button
      key={name}
      aria-label={name}
      onClick={onClick}
      style={style}
      isSelected={isSelected}
    >
      <IonIcon icon={ionicons[name]} />
    </Button>
  );
}

export default React.memo(IconButton);

import * as React from "react";
import digGet from "lodash/get";
import { Context } from "../models";
import { getFlatPath } from "../utils";
import { Heading, Divider } from "./";
import renderInspector from "./inspectors/renderer";

const Inspector: React.FC = () => {
  const {
    store: { selection, nodes, currentPath },
    dispatch
  } = React.useContext(Context);

  const selectedId = selection[0]?.id;
  const path = getFlatPath(currentPath).slice();
  const children = digGet(nodes, path) as INode[];

  if (children) {
    const inspectedNode =
      children.find(node => node && node.id === selectedId) || null;

    if (inspectedNode === null) {
      return inspectedNode;
    } else {
      let ui = null;

      const screens = [...nodes.map((n: INode) => n.properties.elementName)];

      ui = renderInspector(inspectedNode, dispatch, screens);

      return (
        <div key={inspectedNode.id}>
          <Heading>{inspectedNode.type}</Heading>
          <Divider />
          <div style={{ overflowY: "scroll", height: "calc(100vh - 82px)" }}>
            {ui}
          </div>
        </div>
      );
    }
  }

  return <span>Please select a node</span>;
};

export default React.memo(Inspector);

import * as React from "react";
import { IonText } from "@ionic/react";

const EnhancedIonText: React.FC<INodeIonText["properties"]> = ({
  color,
  content,
  size,
  ...rest
}) => {
  return (
    <IonText color={color} {...rest}>
      {React.createElement((size || "p") as string, {
        children: [content]
      })}
    </IonText>
  );
};

export default EnhancedIonText;

import styled from "styled-components";

const Notice = styled.p`
  margin: 8px 0 0;
  background-color: #f8f8e7;
  color: #a0522d;
  padding: 8px;
  font-size: 11px;
`;

export default Notice;

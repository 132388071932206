import produce from "immer";
import { getChildrenAtPath, deleteNodeAtPath } from "../utils";
import * as templates from "./templates";
import NodeType from "./node-types";
import initialData from "./data";

type ITemplates = {
  [key: string]: () => INode;
};

const reducer: React.Reducer<IStore, IAction> = (
  state: IStore,
  action: IAction
): IStore => {
  return produce(state, draft => {
    switch (action.type) {
      case "select": {
        const { currentPath } = draft;
        const newPath = action.node.path
          .slice()
          .splice(0, action.node.path.length - 1);

        // don't navigate if the selected node is on the same path
        if (
          currentPath[currentPath.length - 1]?.id !==
          newPath[newPath.length - 1]?.id
        ) {
          draft.currentPath = newPath;
        }
        draft.selection = [action.node];
        draft.hover = null;
        break;
      }
      case "remove-from-selection": {
        draft.selection = draft.selection.filter(
          node => node.id !== action.node.id
        );
        break;
      }
      case "deselect-all": {
        draft.selection = [];
        break;
      }
      case "add-to-selection": {
        draft.selection.push(action.node);
        break;
      }
      case "reorder": {
        const { nodes, currentPath } = draft;
        const currentItemChildren: INode[] = getChildrenAtPath(
          nodes,
          currentPath
        );
        const { startIndex, endIndex } = action.node;
        const [removed] = currentItemChildren.splice(startIndex, 1);
        currentItemChildren.splice(endIndex, 0, removed);
        break;
      }
      case "move-artboard": {
        const node = draft.nodes.find(({ id }) => id === action.node.id);

        if (node) {
          node.properties.transform = {
            x: action.node.x,
            y: action.node.y
          };
        }
        break;
      }
      case "open-node": {
        draft.selection = [];
        draft.currentPath.push(action.pathItem);
        break;
      }
      case "open-artboard": {
        draft.selection = [];
        draft.currentPath = [action.pathItem];
        break;
      }
      case "hover": {
        draft.hover = action.node;
        break;
      }
      case "back-to-node": {
        draft.selection = [];
        draft.currentPath = draft.currentPath.slice(0, -1);
        break;
      }
      case "update-properties": {
        const { nodes, currentPath } = draft;
        const currentItemChildren: INode[] = getChildrenAtPath(
          nodes,
          currentPath
        );

        currentItemChildren.forEach((node: INode) => {
          if (node.id === action.node.id) {
            if (action.node.properties.children) {
              node.children = [action.node.properties.children];
            } else {
              node.properties = {
                ...node.properties,
                ...action.node.properties
              };
            }
          }
        });
        break;
      }
      case "delete-node": {
        // TODO: Update for multiselection when it happens
        // selection is an array (implemented for multiselection in the future)
        // because we only support single selection now, we'l get the first selection
        // later on when we support multiple selection this method has to be updated
        if (draft.selection.length > 0) {
          const path = [...draft.selection[0].path];
          draft.selection = [];
          deleteNodeAtPath(path, draft.nodes);
        }
        break;
      }
      case "add-node": {
        const { nodes, currentPath } = draft;
        const currentItemChildren: INode[] = getChildrenAtPath(
          nodes,
          currentPath
        );

        const template = (templates as ITemplates)[action.node.type];

        if (template) {
          const newNode = { ...template() };

          if (newNode.type === NodeType.Artboard) {
            const lastArtboard =
              currentItemChildren[currentItemChildren.length - 1];
            if (lastArtboard) {
              newNode.properties.transform.x =
                lastArtboard.properties.transform.x + 400;
              newNode.properties.transform.y =
                lastArtboard.properties.transform.y;
            }
          }

          currentItemChildren.push(newNode);
          draft.selection = [
            {
              id: newNode.id,
              elementName: newNode.properties.elementName,
              path: currentPath
            }
          ];
        } else {
          console.warn(`Type ${action.node.type} has no premade template`);
        }
        break;
      }
      case "toggle-preview": {
        draft.showPreview = !draft.showPreview;
        break;
      }
      case "reset-data": {
        localStorage.setItem("data", JSON.stringify(initialData));
        window.location.reload();
        break;
      }
      default: {
        neverReached(action);
        return state;
      }
    }
  });
};

function neverReached(never: never) {}

export default reducer;

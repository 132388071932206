import * as React from "react";
import styled from "styled-components";

const Stack = styled.div<{
  direction?: string;
  padding?: number;
  itemSpacing?: number;
}>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === "horizontal" ? "row" : "column"};
  padding: ${({ padding }) => `${padding || 0}px`};

  & > *:not(:last-child) {
    ${({ direction, itemSpacing }) =>
      direction === "horizontal"
        ? `margin-right: ${itemSpacing || 0}px`
        : `margin-bottom: ${itemSpacing || 0}px`};
  }
`;

const IonStack: React.FC<INodeIonStack["properties"]> = ({
  direction,
  distribute,
  align,
  wrap,
  padding,
  children,
  itemSpacing,
  ...rest
}) => {
  return (
    <Stack
      padding={padding}
      direction={direction}
      itemSpacing={itemSpacing}
      className={`${distribute || ""} ${align || ""} ${wrap || ""}`}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export default IonStack;

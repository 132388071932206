import * as React from "react";
import { Context } from "../models";
import usePortal from "react-useportal";
import { BoundingBox } from "../components";
import uuid from "uuid/v4";

function Selectable({ as: Component, ...rest }: any) {
  const { store, dispatch, wheeling } = React.useContext(Context);

  function handleHover(event: React.MouseEvent) {
    event.stopPropagation();
    const isSelected = store.selection.find(
      ({ id }: ISelectionItem) => id === rest.id
    );

    if (isSelected) return;

    if (event.type === "mouseover") {
      dispatch({
        type: "hover",
        node: {
          id: rest.id,
          elementName: rest.elementName,
          path: rest.path
        }
      });
    } else {
      dispatch({
        type: "hover",
        node: null
      });
    }
  }

  function handleClick(event: MouseEvent) {
    event.stopPropagation();
    // const isSelected = store.selection.find(({ id }) => id === rest.id);
    //   if (event.shiftKey) {
    //     if (isSelected) {
    //       dispatch({
    //         type: "remove-from-selection",
    //         node: { id: rest.id, name: rest.elementName, path: rest.path, rect }
    //       });
    //     } else {
    //       dispatch({
    //         type: "add-to-selection",
    //         node: { id: rest.id, name: rest.elementName, path: rest.path, rect }
    //       });
    //       dispatch({
    //         type: "hover",
    //         node: null
    //       });
    //     }
    //   } else {
    //     dispatch({
    //       type: "select",
    //       node: { id: rest.id, name: rest.elementName, path: rest.path, rect }
    //     });
    //     dispatch({
    //       type: "hover",
    //       node: null
    //     });
    //   }

    dispatch({
      type: "select",
      node: { id: rest.id, elementName: rest.elementName, path: rest.path }
    });
  }

  const { Portal } = usePortal({
    bindTo: document.getElementById("canvas-pane") || undefined
  });

  const isSelected = store.selection.find(({ id }) => id === rest.id);
  const isHovered = store.hover?.id === rest.id;

  return (
    <>
      <Portal>
        {!wheeling && isHovered && !isSelected && (
          <BoundingBox id={rest.id} name={rest.elementName} hover={true} />
        )}
        {!wheeling && isSelected && !isHovered && (
          <BoundingBox
            id={rest.id}
            name={rest.elementName}
            hover={false}
            key={uuid()}
          />
        )}
      </Portal>
      <Component
        {...rest}
        onClick={handleClick}
        onMouseOut={handleHover}
        onMouseOver={handleHover}
      />
    </>
  );
}

export default Selectable;

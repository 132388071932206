import styled from "styled-components";

const Select = styled.select`
  appearance: none;
  line-height: 24px;
  height: 24px;
  padding: 0 8px;
  border: 1px solid #eee;
  border-radius: 3px;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 0 8px;

  &:hover {
    border-color: #999;
  }

  &:focus {
    border: 1px solid #3273dc;
    outline: none;
    box-shadow: 0 0 0 1px rgba(50, 115, 220, 0.25);
  }

  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4 4L7 1' stroke='%234C4C4C'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
`;

export default Select;

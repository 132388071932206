import NodeType from "./node-types";
import uuid4 from "uuid/v4";

export function IonButton(): INode {
  return {
    id: uuid4(),
    type: NodeType.IonButton,
    isLeaf: true,
    properties: {
      elementName: "Button",
      transform: {
        x: 0,
        y: 0
      },
      label: "Button"
    }
  };
}
export function Artboard(): INode {
  return {
    id: uuid4(),
    type: NodeType.Artboard,
    isLeaf: false,
    children: [],
    properties: {
      elementName: "Artboard",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonGrid(): INode {
  return {
    id: uuid4(),
    type: NodeType.IonGrid,
    isLeaf: false,
    children: [IonRow([IonCol(), IonCol()]), IonRow([IonCol(), IonCol()])],
    properties: {
      elementName: "Grid",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonRow(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonRow,
    isLeaf: false,
    children: [...(children ? children : [])],
    properties: {
      elementName: "Row",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonCol(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonCol,
    isLeaf: false,
    children: [...(children ? children : [])],
    properties: {
      elementName: "Column",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonLabel(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonLabel,
    isLeaf: true,
    children: ["Label"],
    properties: {
      elementName: "Label",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonList(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonList,
    isLeaf: false,
    properties: {
      elementName: "List",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonItem(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonItem,
    isLeaf: false,
    properties: {
      elementName: "Item",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonStack(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonStack,
    isLeaf: false,
    children: [...(children ? children : [])],
    properties: {
      elementName: "Stack",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonText(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonText,
    isLeaf: true,
    properties: {
      elementName: "Text",
      content: "New text",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonCardContent(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonCardContent,
    isLeaf: true,
    children: ["Card Content"],
    properties: {
      elementName: "Card Content",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonCardHeader(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonCardHeader,
    isLeaf: false,
    children: [...(children ? children : [])],
    properties: {
      elementName: "Card Header",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonCardSubtitle(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonCardSubtitle,
    isLeaf: true,
    children: ["Card Subtitle"],
    properties: {
      elementName: "Card Subtitle",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonCardTitle(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonCardTitle,
    isLeaf: true,
    children: ["Card Title"],
    properties: {
      elementName: "Card Title",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonCard(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonCard,
    isLeaf: false,
    children: [
      IonImg(),
      IonCardHeader([IonCardSubtitle(), IonCardTitle()]),
      IonCardContent()
    ],
    properties: {
      elementName: "Card",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonBadge(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonBadge,
    isLeaf: true,
    children: ["34K"],
    properties: {
      elementName: "Badge",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonCheckbox(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonCheckbox,
    isLeaf: true,
    properties: {
      elementName: "Checkbox",
      value: "Checkbox",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonRadio(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonRadio,
    isLeaf: true,
    properties: {
      elementName: "Radio",
      value: "Radio",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonRadioGroup(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonRadioGroup,
    isLeaf: false,
    children: [...(children ? children : [])],
    properties: {
      elementName: "Radio Group",
      allowEmptySelection: true,
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonImg(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonImg,
    isLeaf: true,
    properties: {
      elementName: "Image",
      alt: "Placeholder Image",
      src: "https://via.placeholder.com/250x100",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}
export function IonChip(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonChip,
    isLeaf: true,
    children: ["Chip"],
    properties: {
      elementName: "Chip",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}

export function IonInput(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonInput,
    isLeaf: true,
    properties: {
      elementName: "Input",
      value: "Input",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}

export function IonIcon(children?: INode[]): INode {
  return {
    id: uuid4(),
    type: NodeType.IonIcon,
    isLeaf: true,
    properties: {
      elementName: "Icon",
      icon: "heart",
      transform: {
        x: 0,
        y: 0
      }
    }
  };
}

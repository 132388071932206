import * as React from "react";

import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonBadge,
  IonCheckbox,
  IonRadio,
  IonRadioGroup,
  IonImg,
  IonChip,
  IonInput
} from "@ionic/react";

import { IonButton, IonStack, IonText, IonIcon } from "./enhanced-ionic";
import Selectable from "./selectable";
import { StaticContext, RouteComponentProps } from "react-router";

const ionic: { [key: string]: any } = {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonBadge,
  IonButton,
  IonStack,
  IonText,
  IonCheckbox,
  IonRadio,
  IonRadioGroup,
  IonImg,
  IonChip,
  IonInput,
  IonIcon
};

const enahnceComponent = (
  type: string,
  preview: boolean = false
): JSX.Element | null => {
  const Component = ionic[type];
  return (!preview ? <Selectable as={Component} /> : <Component />) || null;
};

const getComponent = (
  item: INode,
  itemPath: IPathItem,
  pagePath: IPathItem,
  path: IPathItem[] = [],
  preview: boolean = false,
  routeProps?: RouteComponentProps<any, StaticContext, {} | null | undefined>
): any => {
  const newPath = [...path, itemPath];
  if (typeof item === "string") return item;
  const { type, id, children, properties } = item;
  return React.cloneElement(enahnceComponent(type, preview) as JSX.Element, {
    id,
    key: id,
    path: [pagePath, ...newPath],
    routeProps: routeProps,
    ...properties,
    children:
      typeof children !== "undefined"
        ? children.map((child, grandChildIndex) =>
            typeof child === "string"
              ? child
              : getComponent(
                  child,
                  {
                    id: child.id,
                    elementName: child.properties.elementName,
                    index: grandChildIndex
                  },
                  pagePath,
                  newPath,
                  preview,
                  routeProps
                )
          )
        : null
  });
};

const Page: React.FC<{
  items: (INode | string)[] | undefined;
  pagePath: IPathItem;
  preview?: boolean;
  routeProps?: RouteComponentProps<any, StaticContext, {} | null | undefined>;
}> = ({ items, pagePath, preview, routeProps }) => {
  return (
    <IonPage>
      <IonContent>
        {items?.map((item, index) =>
          typeof item === "string"
            ? item
            : getComponent(
                item,
                {
                  id: item.id,
                  elementName: item.properties.elementName,
                  index
                },
                pagePath,
                [],
                preview,
                routeProps
              )
        )}
      </IonContent>
    </IonPage>
  );
};

export default Page;

import * as React from "react";
import styled from "styled-components";

const ReadView = styled.div`
  line-height: 32px;
  height: 32px;
  padding: 0 8px;
  flex-shrink: 1;
  white-space: pre;
  font-feature-settings: "calt" on, "ss02" on;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: #999;
  }
`;

const EditView = styled.input`
  line-height: 24px;
  height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 0 5px;
  margin: 0 2px;

  width: 100%;

  &:focus {
    border: 1px solid #3273dc;
    outline: none;
    box-shadow: 0 0 0 1px rgba(50, 115, 220, 0.25);
  }
`;

interface State {
  isEditing: boolean;
  defaultValue: string;
  value: string;
}

interface Props {
  value: string;
  onConfirm: Function;
}

export default class InlineEditable extends React.Component<Props, State> {
  state = {
    isEditing: false,
    defaultValue: this.props.value || "",
    value: this.props.value || ""
  };

  handleConfirm = (value: string) => {
    if (value.length === 0) {
      this.setState({ value: this.props.value, isEditing: false });
    } else {
      this.setState({ isEditing: false });
      this.props.onConfirm(value);
    }
  };

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.handleConfirm(event.target.value);
  };

  handleDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({ isEditing: true });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 27) {
      this.setState({ value: this.props.value, isEditing: false });
    } else if (event.keyCode === 13) {
      this.handleConfirm((event.target as HTMLInputElement).value);
    }
  };

  handleClickOutside = (event: any) => {
    const tagName: string = (event.target as HTMLElement).tagName;

    if (tagName !== "INPUT" && this.state.isEditing) {
      this.handleConfirm(this.state.value);
    }
  };

  componentDidMount() {
    document.body.addEventListener("click", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleClickOutside, false);
  }

  render() {
    const { isEditing, value } = this.state;

    if (isEditing) {
      return (
        <EditView
          type="text"
          autoFocus
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      );
    } else {
      return (
        <ReadView onDoubleClick={this.handleDoubleClick}>{value}</ReadView>
      );
    }
  }
}

import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import "@ionic/react/css/ionic.bundle.css";
import "./index.css";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

LogRocket.init("unu9tq/prosheet");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

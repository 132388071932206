import Fuse from "fuse.js";
import * as React from "react";

function splitArray(array: any, part: any) {
  const tmp = [];
  for (let i = 0; i < array.length; i += part) {
    tmp.push(array.slice(i, i + part));
  }
  return tmp;
}

function useSearch(query: string, meta: any) {
  const [results, setResults] = React.useState(meta);

  const fuse = new Fuse(meta, {
    threshold: 0.2,
    keys: ["ios", "md", "tags"]
  });

  React.useEffect(() => {
    if (query.trim()) {
      setResults(fuse.search(query.trim()));
    } else {
      setResults(meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, meta]);

  return splitArray(results, 4);
}

export default useSearch;

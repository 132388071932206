import * as React from "react";
import styled from "styled-components";
import usePortal from "react-useportal";
import clamp from "lodash/clamp";
import Blanket from "../../blanket";

const Wrapper = styled.div<{
  x: number;
  y: number;
  width?: number;
  height?: number;
}>`
  position: absolute;
  top: ${props => `${props.y}px`};
  left: ${props => `${props.x}px`};
  background-color: #fff;
  z-index: 301;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: ${props => (props.width ? `${props.width}px` : "202px")};
  height: ${props => (props.height ? `${props.height}px` : "202px")};
  overflow-y: scroll;

  animation-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
  animation-duration: 0.2s;
  animation-name: fadeIn;
`;

interface ISelect {
  trigger: React.ReactElement;
  children?: React.ReactNode;
  width?: number;
  height?: number;
}

const Select = (props: ISelect) => {
  const { trigger, children, width, height } = props;
  const [state, setState] = React.useState({ open: false, x: 0, y: 0 });

  const { Portal } = usePortal();

  const toggle = (e: React.MouseEvent<HTMLElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setState({
      ...state,
      open: !state.open,
      x: rect.x - (width || 202) - 8,
      y: clamp(rect.top, 8, window.innerHeight - (height || 202) - 8)
    });
  };

  const closeMenu = () => {
    setState({
      ...state,
      open: false
    });
  };

  const Trigger = React.cloneElement(trigger, {
    ...trigger?.props,
    onClick: toggle
  });

  return (
    <>
      {Trigger}
      {state.open && (
        <Portal>
          <Wrapper x={state.x} y={state.y} height={height} width={width}>
            {children}
          </Wrapper>
          <Blanket onClick={closeMenu} />
        </Portal>
      )}
    </>
  );
};

export default Select;

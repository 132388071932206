import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.label<{ small: boolean }>`
  position: relative;
  display: inline-block;

  width: ${({ small }) => (small ? "20px" : "36px")};
  height: ${({ small }) => (small ? "12px" : "20px")};
`;

const Checkbox = styled.input.attrs({
  type: "checkbox"
})`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const Slider = styled.span<{ small: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 0.4s;

  cursor: pointer;
  background-color: #ccc;
  border-radius: 36px;

  ${Checkbox}:checked + & {
    background-color: #000;
  }

  ${Checkbox}:focus + & {
    box-shadow: 0 0 1px #3b97d3;
  }

  &:hover:before {
    background-color: #f0f0f0;
  }

  &:before {
    position: absolute;
    content: "";
    left: 2px;
    bottom: 2px;

    transition: 0.3s;

    height: ${({ small }) => (small ? "8px" : "16px")};
    width: ${({ small }) => (small ? "8px" : "16px")};
    background-color: #fff;
    border-radius: ${({ small }) => (small ? "8px" : "16px")};

    ${Checkbox}:checked + & {
      transform: translateX(${({ small }) => (small ? "8px" : "16px")});
    }

    /* ${Checkbox}:active + & {
      width: ${({ small }) => (small ? "10px" : "20px")};
    }

    ${Checkbox}:checked:active + & {
      transform: translateX(${({ small }) => (small ? "6px" : "16px")});
    } */
  }
`;

const Switch = (props: any) => (
  <Wrapper small={props.small}>
    <Checkbox {...props} />
    <Slider small={props.small} />
  </Wrapper>
);

export default Switch;

import styled from "styled-components";

const Title = styled.div`
  font-weight: 600;
  text-transform: capitalize;
  font-size: 11px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Title;

import React from "react";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
  ResponderProvided
} from "react-beautiful-dnd";
import styled from "styled-components";
import usePortal from "react-useportal";
import Fuse from "fuse.js";

import { List, Item, ListHeadingButton, Divider, Heading, Blanket } from "./";

import { Context, NodeType } from "../models";
import { getChildrenAtPath } from "../utils";

const getDraggingStyle = (
  draggableStyle?: DraggingStyle | NotDraggingStyle | undefined
) => {
  if (draggableStyle) {
    draggableStyle.transform = draggableStyle.transform?.replace(
      /(translate\()(.+),/,
      "$10,"
    );
  }
  return {
    ...draggableStyle
  };
};

const elements = [
  { type: NodeType.IonButton, title: "Button" },
  { type: NodeType.IonStack, title: "Stack" },
  { type: NodeType.IonGrid, title: "Grid" },
  { type: NodeType.IonRow, title: "Row" },
  { type: NodeType.IonCol, title: "Column" },
  { type: NodeType.IonText, title: "Text" },
  { type: NodeType.IonLabel, title: "Label" },
  { type: NodeType.IonCard, title: "Card" },
  { type: NodeType.IonCardContent, title: "Card Content" },
  { type: NodeType.IonCardHeader, title: "Card Header" },
  { type: NodeType.IonCardSubtitle, title: "Card Subtitle" },
  { type: NodeType.IonCardTitle, title: "Card Title" },
  { type: NodeType.IonBadge, title: "Badge" },
  { type: NodeType.IonCheckbox, title: "Checkbox" },
  { type: NodeType.IonRadio, title: "Radio" },
  { type: NodeType.IonRadioGroup, title: "Radio Group" },
  { type: NodeType.IonImg, title: "Image" },
  { type: NodeType.IonChip, title: "Chip" },
  { type: NodeType.IonInput, title: "Input" },
  { type: NodeType.IonIcon, title: "Icon" }
];

const fusedElements = new Fuse(elements, {
  threshold: 0.4,
  keys: ["title"]
});

const MenuButton = styled.button`
  padding: 16px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  color: black;
  outline: none;
  margin: 8px 8px 0;
  box-sizing: border-box;
  width: calc(100% - 16px);

  &:last-child {
    margin: 8px 8px 8px;
  }

  &:hover {
    background-color: #eee;
  }
`;

const SearchInput = styled.input`
  color: black;
  font-size: 11px;
  background: none;
  padding: 16px;
  outline: none;
  border: 0px;
  border-radius: 3px 3px 0 0;
  height: 48px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;

  &:hover,
  &:active,
  &:focus {
    background-color: #fff;
  }
`;

const ComponentsContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 216px;
  background-color: #fff;
  z-index: 301;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: 200px;
  height: 400px;
  overflow-y: scroll;

  animation-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
  animation-duration: 0.2s;
  animation-name: fadeIn;
`;

const NodeList: React.FC<{ id: string }> = ({ id }) => {
  const [transition, setTransition] = React.useState("");
  const { store, dispatch } = React.useContext(Context);

  const selection = store.selection;
  const currentPath = store.currentPath;
  const nodes = store.nodes;

  React.useEffect(() => {
    localStorage.setItem("data", JSON.stringify(store));
  });

  const prevPath = React.useRef(currentPath);

  React.useEffect(() => {
    const lastPrevPathItemId =
      prevPath.current[prevPath.current.length - 1]?.id;
    const lastCurrentPathItemId = currentPath[currentPath.length - 1]?.id;
    if (prevPath.current.length < currentPath.length)
      setTransition(
        transition === "slide-forward" ? "slide-forward-alt" : "slide-forward"
      );
    else if (prevPath.current.length > currentPath.length) {
      setTransition(
        transition === "slide-back" ? "slide-back-alt" : "slide-back"
      );
    } else if (
      prevPath.current.length === currentPath.length &&
      lastPrevPathItemId !== lastCurrentPathItemId
    ) {
      setTransition(
        transition === "slide-back" ? "slide-back-alt" : "slide-back"
      );
    }

    prevPath.current = currentPath;
  }, [currentPath, transition]);

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    dispatch({
      type: "reorder",
      node: {
        startIndex: result.source.index,
        endIndex: result.destination.index
      }
    });
  };

  const onItemClick = (
    event: React.MouseEvent<HTMLElement>,
    pathItem: IPathItem
  ) => {
    event.stopPropagation();

    const isSelected =
      selection.filter(
        (selectable: ISelectionItem) => selectable.id === pathItem.id
      ).length > 0;
    // if (event.metaKey || event.shiftKey) {
    //   if (isSelected) {
    //     dispatch({
    //       type: "remove-from-selection",
    //       node: {
    //         id: pathItem.id,
    //         name: pathItem.elementName,
    //         rect,
    //         path: currentPath
    //       }
    //     });
    //   } else {
    //     dispatch({
    //       type: "add-to-selection",
    //       node: {
    //         id: pathItem.id,
    //         name: pathItem.elementName,
    //         rect,
    //         path: currentPath
    //       }
    //     });
    //   }
    // } else {
    //   dispatch({
    //     type: "select",
    //     node: { id: pathItem.id, name: pathItem.elementName, rect, path: currentPath }
    //   });
    // }

    if (!isSelected) {
      dispatch({
        type: "select",
        node: {
          id: pathItem.id,
          elementName: pathItem.elementName,
          path: [...currentPath, pathItem]
        }
      });
    }
  };

  const onItemDoubleClick = (pathItem: IPathItem, isLeaf: boolean) => {
    if (isLeaf) return;

    dispatch({
      type: "open-node",
      pathItem: pathItem
    });

    setTransition(
      transition === "slide-forward" ? "slide-forward-alt" : "slide-forward"
    );
  };

  const onBackClick = () => {
    dispatch({
      type: "back-to-node"
    });

    setTransition(
      transition === "slide-back" ? "slide-back-alt" : "slide-back"
    );
  };

  const onAddClick = (event: React.MouseEvent) => {
    if (currentPath.length === 0) {
      dispatch({
        type: "add-node",
        node: {
          type: NodeType.Artboard
        }
      });
    } else {
      setIsElementsMenuOpen(true);
    }
  };

  const [menuFilter, setMenuFilter] = React.useState<string>("");

  const [isElementsMenuOpen, setIsElementsMenuOpen] = React.useState(false);
  const currentItemChildren: INode[] = getChildrenAtPath(nodes, currentPath);

  let searchInput = React.createRef<HTMLInputElement>();

  function closeMenu() {
    setIsElementsMenuOpen(false);
  }

  const addElement = React.useCallback(
    (type: NodeType | string) => {
      dispatch({
        type: "add-node",
        node: {
          type
        }
      });
      closeMenu();
    },
    [dispatch]
  );

  const { Portal } = usePortal();

  const filteredElements =
    menuFilter.length > 0 ? fusedElements.search(menuFilter) : elements;

  return (
    <>
      <Portal>
        {isElementsMenuOpen && (
          <>
            <ComponentsContainer>
              <SearchInput
                placeholder="Search Elements"
                autoFocus
                ref={searchInput}
                value={menuFilter}
                onChange={e => {
                  setMenuFilter(e.currentTarget.value);
                }}
              />
              {filteredElements.map((element, index) => (
                <MenuButton
                  id="element"
                  onClick={() => addElement(element.type)}
                  key={index}
                >
                  {element.title}
                </MenuButton>
              ))}
            </ComponentsContainer>
            <Blanket onClick={closeMenu} />
          </>
        )}
      </Portal>
      <Heading className={transition}>
        {currentPath.length > 0 && (
          <ListHeadingButton onClick={onBackClick} side="left">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289Z"
                fill="currentColor"
              />
            </svg>
          </ListHeadingButton>
        )}
        {currentPath.length === 0
          ? "Screens"
          : currentPath[currentPath.length - 1].elementName}

        <ListHeadingButton onClick={onAddClick} side="right">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 6C11.4477 6 11 6.44771 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44772 12.5523 6 12 6Z"
              fill="currentColor"
            />
          </svg>
        </ListHeadingButton>
      </Heading>
      <Divider />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={transition}
              id={id}
            >
              {currentItemChildren?.map((item: INode, index: number) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <Item
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getDraggingStyle(provided.draggableProps.style)}
                      onClick={event =>
                        onItemClick(event, {
                          id: item.id,
                          elementName: item.properties.elementName,
                          index
                        })
                      }
                      onDoubleClick={() =>
                        onItemDoubleClick(
                          {
                            id: item.id,
                            elementName: item.properties.elementName,
                            index
                          },
                          item.isLeaf
                        )
                      }
                      isSelected={
                        selection.filter(
                          (selectable: ISelectionItem) =>
                            selectable.id === item.id
                        ).length > 0
                      }
                      isDragging={snapshot.isDragging}
                    >
                      {item.properties.elementName.length > 0 ? (
                        item.properties.elementName
                      ) : (
                        <i>{item.type}</i>
                      )}
                      {!item.isLeaf && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.64645 7.64645C9.84171 7.45118 10.1583 7.45118 10.3536 7.64645L14.3536 11.6464C14.5488 11.8417 14.5488 12.1583 14.3536 12.3536L10.3536 16.3536C10.1583 16.5488 9.84171 16.5488 9.64645 16.3536C9.45118 16.1583 9.45118 15.8417 9.64645 15.6464L13.2929 12L9.64645 8.35355C9.45118 8.15829 9.45118 7.84171 9.64645 7.64645Z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                    </Item>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default NodeList;

import styled from "styled-components";

const Panel = styled.div<{ compact?: boolean }>`
  border-bottom: 1px solid #f0f0f0;
  padding: ${({ compact }) => (compact ? "8px" : "8px 8px 16px")};
  display: flex;
  flex-direction: ${({ compact }) => (compact ? "row" : "column")};
  justify-content: ${({ compact }) =>
    compact ? "space-between" : "flex-start"};
  align-items: ${({ compact }) => (compact ? "center" : "stretch")};
  user-select: none;
  color: #333;
`;

export default Panel;

import styled from "styled-components";

const Control = styled.div`
  font-weight: 400;
  text-transform: capitalize;
  font-size: 13px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Control;

enum NodeType {
  Root = "Root",
  Artboard = "Artboard",
  IonActionSheet = "IonActionSheet",
  IonActionSheetController = "IonActionSheetController",
  IonAlert = "IonAlert",
  IonAlertController = "IonAlertController",
  IonAnchor = "IonAnchor",
  IonApp = "IonApp",
  IonAvatar = "IonAvatar",
  IonBackButton = "IonBackButton",
  IonBackdrop = "IonBackdrop",
  IonBadge = "IonBadge",
  IonButton = "IonButton",
  IonButtons = "IonButtons",
  IonCard = "IonCard",
  IonCardContent = "IonCardContent",
  IonCardHeader = "IonCardHeader",
  IonCardSubtitle = "IonCardSubtitle",
  IonCardTitle = "IonCardTitle",
  IonCheckbox = "IonCheckbox",
  IonChip = "IonChip",
  IonCol = "IonCol",
  IonContent = "IonContent",
  IonDatetime = "IonDatetime",
  IonFab = "IonFab",
  IonFabButton = "IonFabButton",
  IonFabList = "IonFabList",
  IonFooter = "IonFooter",
  IonGrid = "IonGrid",
  IonHeader = "IonHeader",
  IonImg = "IonImg",
  IonInfiniteScroll = "IonInfiniteScroll",
  IonInfiniteScrollContent = "IonInfiniteScrollContent",
  IonInput = "IonInput",
  IonItem = "IonItem",
  IonItemDivider = "IonItemDivider",
  IonItemGroup = "IonItemGroup",
  IonItemOption = "IonItemOption",
  IonItemOptions = "IonItemOptions",
  IonItemSliding = "IonItemSliding",
  IonLabel = "IonLabel",
  IonList = "IonList",
  IonListHeader = "IonListHeader",
  IonLoading = "IonLoading",
  IonLoadingController = "IonLoadingController",
  IonMenu = "IonMenu",
  IonMenuButton = "IonMenuButton",
  IonMenuController = "IonMenuController",
  IonMenuToggle = "IonMenuToggle",
  IonModal = "IonModal",
  IonModalController = "IonModalController",
  IonNav = "IonNav",
  IonNavLink = "IonNavLink",
  IonNavPop = "IonNavPop",
  IonNavPush = "IonNavPush",
  IonNavSetRoot = "IonNavSetRoot",
  IonNote = "IonNote",
  IonPicker = "IonPicker",
  IonPickerController = "IonPickerController",
  IonPopover = "IonPopover",
  IonPopoverController = "IonPopoverController",
  IonProgressBar = "IonProgressBar",
  IonRadio = "IonRadio",
  IonRadioGroup = "IonRadioGroup",
  IonRange = "IonRange",
  IonRefresher = "IonRefresher",
  IonRefresherContent = "IonRefresherContent",
  IonReorder = "IonReorder",
  IonReorderGroup = "IonReorderGroup",
  IonRippleEffect = "IonRippleEffect",
  IonRoute = "IonRoute",
  IonRouter = "IonRouter",
  IonRouteRedirect = "IonRouteRedirect",
  IonRouterLink = "IonRouterLink",
  IonRouterOutlet = "IonRouterOutlet",
  IonRow = "IonRow",
  IonSearchbar = "IonSearchbar",
  IonSegment = "IonSegment",
  IonSegmentButton = "IonSegmentButton",
  IonSelect = "IonSelect",
  IonSelectOption = "IonSelectOption",
  IonSkeletonText = "IonSkeletonText",
  IonSlide = "IonSlide",
  IonSlides = "IonSlides",
  IonSpinner = "IonSpinner",
  IonSplitPane = "IonSplitPane",
  IonTab = "IonTab",
  IonTabBar = "IonTabBar",
  IonTabButton = "IonTabButton",
  IonTabs = "IonTabs",
  IonText = "IonText",
  IonTextarea = "IonTextarea",
  IonThumbnail = "IonThumbnail",
  IonTitle = "IonTitle",
  IonToast = "IonToast",
  IonToastController = "IonToastController",
  IonToggle = "IonToggle",
  IonToolbar = "IonToolbar",
  IonStack = "IonStack",
  IonIcon = "IonIcon"
}

export default NodeType;

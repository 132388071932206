import styled from "styled-components";

const Blanket = styled.div<{ bright?: boolean; dark?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 110;
  background-color: ${({ bright, dark }) =>
    bright
      ? "rgba(255, 255, 255, 0.75)"
      : dark
      ? "rgba(0, 0, 0, 0.5)"
      : "transparent"};
`;

export default Blanket;

import * as React from "react";
import styled from "styled-components";

const Outline = styled.div<{
  top: number;
  left: number;
  width: number;
  height: number;
  hover: boolean;
}>`
  position: fixed;
  top: ${({ top }) => top + "px"};
  left: ${({ left }) => left + "px"};
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  outline: 2px solid
    ${({ hover }) => (hover ? "rgba(12, 64, 247, 0.3)" : "#1CA1FA")};
  z-index: 100;
  user-select: none;
  pointer-events: none;
  font-family: monospace;
`;

interface BoundingBox {
  name: string;
  hover: boolean;
  id: string;
}

const BoundingBox = ({ name, hover = false, id }: BoundingBox) => {
  const [box, setBox] = React.useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0
  });

  React.useEffect(() => {
    const rect = document.getElementById(id)?.getBoundingClientRect();

    setBox({
      width: rect?.width || 0,
      height: rect?.height || 0,
      top: rect?.top || 0,
      left: rect?.left || 0
    });
  }, [id]);

  const { width, height, left, top } = box;

  return (
    <Outline
      width={width}
      height={height}
      top={top}
      left={left}
      hover={hover}
    />
  );
};

export default React.memo(BoundingBox);

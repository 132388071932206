import * as React from "react";
import styled from "styled-components";
import { Context } from "../models";

const ToolbarButton = styled.button`
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #2c2c2c;
  outline: none;

  &:hover {
    background-color: #eee;
  }
`;

const ToolbarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: height 0.1s ease-in-out 0s, width 0.1s ease-in-out 0s,
    padding 0.1s ease-in-out 0s;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

function Toolbar() {
  const { dispatch } = React.useContext(Context);
  return (
    <ToolbarContainer>
      <ToolbarButton
        onClick={() => {
          dispatch({
            type: "toggle-preview"
          });
        }}
      >
        <svg
          width="9"
          height="12"
          viewBox="0 0 9 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 11.0657V0.934259L8.09861 6L0.5 11.0657Z"
            stroke="black"
          />
        </svg>
      </ToolbarButton>
      <ToolbarButton
        onClick={() => {
          if (window.confirm("Do you really want to erase your designs?")) {
            dispatch({
              type: "reset-data"
            });
          }
        }}
      >
        Reset
      </ToolbarButton>
    </ToolbarContainer>
  );
}

export default Toolbar;

import * as React from "react";
import styled from "styled-components";

const Segment = styled.button<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  color: #666;
  line-height: 100%;
  user-select: none;
  border: none;
  outline: none;
  font-size: 11px;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  z-index: 2;
  background: transparent;
  padding: 0 12px;
  margin: 0;
  box-sizing: border-box;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  /* ${({ isSelected }) =>
    isSelected
      ? `
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 1px 2px rgba(0,0,0,0.16);
    `
      : `
      background: transparent;
    `}; */
`;

const Wrapper = styled.div<{
  width: number;
  selectedIndex: number;
  count: number;
}>`
  display: grid;
  grid-template-columns: ${({ count }) => `1fr `.repeat(count)};
  justify-items: stretch;
  align-items: stretch;
  height: 24px;
  background-color: #eee;
  border-radius: 6px;
  border: 2px solid #eee;
  position: relative;
  min-width: 50%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: ${({ width, selectedIndex }) => `${width * selectedIndex}%`};
    bottom: 0;
    width: ${({ width }) => `${width}%`};
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    transition: left 0.1s ease;
    display: ${({ selectedIndex }) =>
      `${selectedIndex === -1 ? "none" : "block"}`};
  }

  &:hover {
    box-shadow: 0 0 0 1px #999;
  }
`;

const Segments: React.FC<{
  options: { value: string; label: React.ReactElement | string }[];
  selected: string;
  onOptionClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ options, selected, onOptionClick }) => {
  const selectedIndex = options.map(option => option.value).indexOf(selected);
  const width = (1 / options.length) * 100;
  return (
    <Wrapper width={width} selectedIndex={selectedIndex} count={options.length}>
      {options.map((option, index) => (
        <Segment
          key={index}
          isSelected={option.value === selected}
          value={option.value}
          onClick={onOptionClick}
        >
          {option.label}
        </Segment>
      ))}
    </Wrapper>
  );
};

export default Segments;

import * as React from "react";
import IconButton from "./icon-button";
import SearchInput from "./search-input";
import useSearch from "./use-search";
import IconsContext from "./icons-context";
import { FixedSizeGrid as Grid } from "react-window";
import Button from "../button";
import PopoverSelect from "../popover-select";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import { IonIcon } from "@ionic/react";
import ionicons from "../../../enhanced-ionic/ionicons";
const { useState, useEffect, useRef } = React;
let version = "5";

function IconSelect({ onIconChange, selectedIcon }: any) {
  const [query, setQuery] = useState(" ");
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState([]);

  const listRef = useRef<null | Grid>(null);

  useEffect(() => {
    (async () => {
      const metaResponse = await fetch(
        `https://cdn.jsdelivr.net/npm/ionicons@${version}/dist/ionicons.json`
      );

      const json = await metaResponse.json();

      setMeta(json.icons);
      setLoading(false);
      setQuery("");
    })();
  }, []);

  const [coords, setCoords] = useState<[number, number] | null>(null);

  useEffect(() => {
    if (coords !== null) {
      listRef.current?.scrollToItem({
        align: "smart",
        rowIndex: coords[0],
        columnIndex: coords[1]
      });
    }
  }, [coords]);

  const results = useSearch(query, meta);

  if (selectedIcon.length > 0 && coords === null) {
    for (let rowIndex = 0; rowIndex < results.length; rowIndex++) {
      const columns = results[rowIndex];
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        const icon = columns[colIndex];
        if (icon.name === selectedIcon) {
          setCoords([rowIndex, colIndex]);
        }
      }
    }
  }

  return (
    <PopoverSelect
      trigger={
        <Button style={{ height: 42, fontSize: 11 }}>
          {selectedIcon ? (
            <>
              <IonIcon
                icon={ionicons[camelCase(selectedIcon)]}
                style={{ fontSize: "18px", margin: "0 8px 0 0", color: "#333" }}
              />
              {startCase(selectedIcon)}
            </>
          ) : (
            "Select Icon"
          )}
        </Button>
      }
      height={253}
    >
      <IconsContext.Provider value={meta}>
        {loading ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Loading
          </div>
        ) : (
          <React.Fragment>
            <SearchInput
              value={query}
              onChange={event => setQuery(event.target.value)}
              style={{
                position: "sticky",
                top: 0,
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
              }}
              count={meta.length}
            />
            <div style={{ padding: "8px 8px 0" }}>
              <Grid
                columnCount={4}
                rowCount={Math.round(results.length)}
                columnWidth={186 / 4}
                rowHeight={186 / 4}
                width={186}
                height={204}
                ref={listRef}
              >
                {({ columnIndex, rowIndex, style }) => {
                  const icon = results[rowIndex][columnIndex];
                  return icon === undefined ? null : (
                    <IconButton
                      name={camelCase(icon.name)}
                      style={style}
                      onClick={() => {
                        if (selectedIcon === icon.name) {
                          onIconChange("");
                        } else {
                          onIconChange(icon);
                        }
                      }}
                      isSelected={selectedIcon === icon.name}
                    />
                  );
                }}
              </Grid>
            </div>
          </React.Fragment>
        )}
      </IconsContext.Provider>
    </PopoverSelect>
  );
}

export default React.memo(IconSelect);

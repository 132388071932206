import { createContext } from "react";

interface Context {
  store: IStore;
  dispatch: IDispatch;
  wheeling: boolean;
}

const StoreContext = createContext({} as Context);
export default StoreContext;

import React from "react";
import get from "lodash/get";
import set from "lodash/set";

export const getFlatPath = (currentPath: IPathItem[]) => [
  ...currentPath.map(({ index }) => [index, "children"]).flat()
];

export const deleteNodeAtPath = (node: IPathItem[], nodes: INode[]) => {
  const path = getFlatPath(node);
  path.pop();
  const index = path.pop() as number;
  if (path.length === 0) {
    //at root
    nodes.splice(index, 1);
  } else {
    const children = get(nodes, path, []) as INode[];
    const subChildren = children.filter(
      (node: INode, i: number) => i !== index
    );
    set(nodes, path, subChildren);
  }
};

export const getChildrenAtPath = (nodes: INode[], currentPath: IPathItem[]) => {
  const path = getFlatPath(currentPath);
  if (path.length === 0) {
    //at root
    return nodes;
  } else {
    let children = get(nodes, path, null);

    if (children === null) {
      set(nodes, path, []);
      children = get(nodes, path, null);
    }

    return children;
  }
};

export function useStateFromProp<T>(
  prop: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState(prop);

  React.useEffect(() => {
    setState(prop);
  }, [prop]);

  return [state, setState];
}

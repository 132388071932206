import styled from "styled-components";

const TextArea = styled.textarea`
  padding: 0 8px;
  border: 1px solid #eee;
  border-radius: 3px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 8px;

  &:hover {
    border-color: #999;
  }

  &:focus {
    border: 1px solid #3273dc;
    outline: none;
    box-shadow: 0 0 0 1px rgba(50, 115, 220, 0.25);
  }

  resize: none;
`;

export default TextArea;

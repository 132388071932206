import * as React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import camelCase from "lodash/camelCase";

import ionicons from "./ionicons";

const EnhancedIonButton: React.FC<INodeIonButton["properties"]> = ({
  label,
  color,
  expand,
  disabled,
  fill,
  mode,
  routerDirection,
  shape,
  size,
  strong,
  href,
  target,
  type,
  icon,
  slot = "start",
  routeProps,
  goto,
  action,
  ...rest
}) => {
  return (
    <IonButton
      color={color}
      size={size}
      shape={shape}
      fill={fill}
      expand={expand}
      disabled={disabled}
      strong={strong}
      {...rest}
      onClick={e => {
        const onClick = (rest as any).onClick;

        if (onClick) {
          onClick(e);
        }

        if (routeProps && action === "goto" && goto) {
          routeProps.history.push(`/${goto}`);
        }
      }}
    >
      {icon && <IonIcon slot={slot} icon={ionicons[camelCase(icon)]} />}
      {slot !== "icon-only" ? label : ""}
    </IonButton>
  );
};

export default EnhancedIonButton;

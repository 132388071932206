import styled from "styled-components";

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #f0f0f0;
  margin: 0;
`;

export default Divider;

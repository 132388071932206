import styled from "styled-components";

const Item = styled.div<{ isSelected?: boolean; isDragging?: boolean }>`
  user-select: none;
  height: 32px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  outline: 0;

  background-color: ${({ isSelected, isDragging }) =>
    isSelected ? "#daebf7" : isDragging ? "#f5f5f5" : "white"};
  &:hover {
    outline: ${({ isSelected }) => (isSelected ? "none" : "1px solid #4d80e4")};
    outline-offset: -1px;
  }

  outline-offset: -1px;
  padding: 0 0 0 8px;

  & svg {
    pointer-events: none;
    flex-shrink: 0;
  }
`;

export default Item;

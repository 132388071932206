import * as React from "react";
import SearchIcon from "./search-icon";

interface SearchInputProps extends React.HTMLProps<HTMLDivElement> {
  value: string;
  count: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function SearchInput({ value, onChange, count, ...props }: SearchInputProps) {
  return (
    <div style={{ position: "relative" }} {...props}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          padding: 8
        }}
      >
        <SearchIcon css={{ fill: "#333" }} />
      </div>
      <input
        autoFocus
        type="text"
        value={value}
        onChange={onChange}
        placeholder={`Search ${count} icons...`}
        style={{
          height: 40,
          padding: `0 16px 0 36px`,
          fontFamily: "inherit",
          fontSize: 12,
          border: 0,
          outline: 0
        }}
      />
    </div>
  );
}

export default SearchInput;

import styled from "styled-components";

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40px;
  width: 100%;
  text-transform: uppercase;
  font-size: 11px;
  color: #222;
  font-weight: bold;
  user-select: none;
  z-index: 1;
`;

export default Heading;
